.notepad {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-size: 1.2em;
  }
  
  .notepad textarea {
    width: 100%;
    min-height: 200px;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-family: monospace;
    font-size: 1.2em;
    border: 1px solid #ccc;

  }
  
  .notepad p {
    display: grid;
    justify-content: center;
    margin-top: 10px;
    font-size: 0.8em;
    color: #555;    
  }
  
  .notepad button {
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 10px;
  }

  .notepad .btnReset {
    background-color: #ff1e00;
  }

  
  
  .notepad button:hover {
    background-color: #3e8e41;
  }
  
  .notepad button:active {
    background-color: #4CAF50;
  }
  
  .notepad .night-mode {
    background-color: #0f0d0d;
    color: #f5f5f5;
}
  
.notepad .night-mode textarea {
    background-color: #303030;
    color: #f5f5f5;
}
  
.notepad .night-mode p {
    color: #f5f5f5;
}
  
.notepad .night-mode button {
    background-color: #424242;
    color: #f5f5f5;
}
  

  