body {
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  h1 {
    font-size: 2rem;
    margin-top: 2rem;
  }
  
  p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  @media (min-width: 768px) {
    /* styles for screens with a minimum width of 768px */
    h1 {
      font-size: 3rem;
    }
  
    p {
      font-size: 1.5rem;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  

  /*About us css*/
ul {
    list-style-type: disc; /* disc, circle, square, etc. */
    margin-left: 20px; /* optional indentation */
    color: rgb(132, 0, 255);
}